<template>
  <div class="book-detail">
    <p class="title">过程方法</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助！</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >笔者已于2011年出版过《过程管理方法》一书，其主要的内容是介绍流程设计和优化的“过程方法”，已得到了读者的普遍认可。2015年国际标准化组织颁布了新版的ISO9001标准，已经明确组织在建立体系时要采用过程方法，证明笔者的多年前的研究已经成为主流思想，再结合这几年来的经验总结，该书再版书名就改为《过程方法》，并将原来第一章分成两个章，将过程风险分析的内容独立成一章——过程风险，其他章节的内容也相应做了修改。</span
      >
      <br />
      <span>
        很多企业面临困境的时候很容易就会想到流程优化。不过，对流程优化跃跃欲试的企业只钟情于成功的一面，殊不知不成功的例子比成功的多得多。不是没有决心，不是没有专家，而是每次的实践都不一样，每次都摸着石头过河，而真正的主角不是专家而是企业自己，专家能有三成的帮助已经了不起了。那么，能否有一种方法完全由企业自己去主导过程优化呢？笔者一直在探索这种方法，本书向大家呈现的就是它的研究成果。
      </span>
      <br />
      <br />
      <div class="divp">
        <span>本书分为10个章节：</span>
        <p><span>第一章</span><span>过程的基本概念</span></p>
        <p><span>第二章</span><span>管理体系模式</span></p>
        <p><span>第三章</span><span>过程识别</span></p>
        <p><span>第四章</span><span>过程风险分析</span></p>
        <p><span>第五章</span><span>过程职责</span></p>
        <p><span>第六章</span><span>过程接口</span></p>
        <p><span>第七章</span><span>过程业绩</span></p>
        <p><span>第八章</span><span>过程简化</span></p>
        <p><span>第九章</span><span>体系优化</span></p>
        <p><span>第十章</span><span>审核过程方法</span></p>
        <p><span>第十一章</span><span>管理评审的过程方法</span></p>
      </div>
      <br />
      <span
        >要打造一流的企业，就是要打造一流的过程。“过程方法”是目前最流行的管理方法，也被实践证明是流程优化最有效的方法，笔者已有多年的实践经验。本书已将“过程方法”的精髓和盘托出，其应用已在很多企业取得成功，相信本书对正要进行流程优化、完善和提升管理体系的企业有所帮助！</span
      >
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>